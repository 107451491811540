$(".cont_toggle input").on('click', function (event) {
    var id = $(this).attr('pid');
    var st = this.value;

    $.ajax({
        url: toggle,
        type: "POST",
        data: {
            id: id,
            st: st,
        },
        success: function (response) {
            __crearMensaje(false, 'Aviso', response.mensaje);
            if (st == 1) {
                $('#txt-item_' + id).html('Inactivo');
                $('input[name="st-item_' + id + '"]').attr('checked', false);
                $('input[name="st-item_' + id + '"]').val(0);
            } else {
                $('#txt-item_' + id).html('Activo');
                $('input[name="st-item_' + id + '"]').attr('checked', true);
                $('input[name="st-item_' + id + '"]').val(1);
            }
        },
        error: function (jqXHR, textStatus, errorThrown) {
            __crearMensaje(true, "Error", jqXHR.statusText);
        }
    });
});
