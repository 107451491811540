// Datepicker jQuery UI
require("jquery-ui/ui/widgets/datepicker");

jQuery(function(){
//$(document).ready(function () {

    // Inicializar
    var dates = $("#date_from").datepicker({
        yearRange: "2021:2050",
        showAnim: 'slideDown',
        showOn: "both",
        buttonImage: "../images/arrow-down-fill.svg",
        changeYear: true,
        changeMonth: true,
        maxDate: '0',
        altField: '#date_from',
        onSelect: function(selectedDate)
        {
            var option = this.id == "date_from" ? "minDate" : "maxDate";
            var instance = $(this).data("datepicker");
            var date = $.datepicker.parseDate(instance.settings.dateFormat || $.datepicker._defaults.dateFormat, selectedDate, instance.settings);
            dates.not(this).datepicker("option", option, date);
        }
    });

    var dates = $("#date_until").datepicker({
        yearRange: "2021:2050",
        showAnim: 'slideDown',
        showOn: "both",
        buttonImage: "../images/arrow-down-fill.svg",
        changeYear: true,
        changeMonth: true,
        maxDate: '+0days',
        altField: '#date_until',
        onSelect: function(selectedDate)
        {
            var option = this.id == "date_from" ? "minDate" : "maxDate";
            var instance = $(this).data("datepicker");
            var date = $.datepicker.parseDate(instance.settings.dateFormat || $.datepicker._defaults.dateFormat, selectedDate, instance.settings);
            dates.not(this).datepicker("option", option, date);
        }
    });



    // Traducción - Español
    $.datepicker.regional['es'] = {
        closeText: 'Cerrar',
        prevText: '<',
        nextText: '>',
        currentText: 'Hoy',
        monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Juv', 'Vie', 'Sáb'],
        dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
        weekHeader: 'Sm',
        dateFormat: 'dd/mm/yy',
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: true,
        yearSuffix: ''
    };
    $.datepicker.setDefaults($.datepicker.regional['es']);
});
