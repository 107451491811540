/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// Boostrap Core
require('./bootstrap');
require('./datepicker');
require('./input-file');
require('./toggle');

// Datatables
var dt = require( 'datatables.net' );
var buttons = require('datatables.net-buttons');

// Global
var token = document.head.querySelector('meta[name="csrf-token"]');
var timercito;

// JS Arquetipo
require('./engine');

// JS Itacate

/**
 * Funcion para validar que las casillas de horario tengan un check
 * por lo menos
 */
function validaHorario(){
  noAbre = true;
  $( ".chk-dia" ).each(function( index ) {
    // console.log( index + ": " +.text() );
    if( $( this ).prop('checked')){
      noAbre = false;
    }
  });
  if(noAbre){
    $('#horarioError').show();
    $('#btn-send').attr('disabled', true);
  }else{
    $('#horarioError').hide();
    $('#btn-send').attr('disabled', false);
  }
}



/**
 * Funcion para validar que las casillas de horario tengan un check
 * por lo menos
 */
 function setAlcaldiaColonia(idAlcaldia, idColonia){
  idAlcaldia = parseInt(idAlcaldia);
  idColonia = parseInt(idColonia);
  $.ajax({
      url: uac,
      type: 'POST',
      data:'{"id_alcaldia":'+idAlcaldia+', "id_colonia":'+idColonia+'}',
      dataType: "json",
      contentType: 'application/json',
      success: function(data) {
      },
      error: function(jqXHR, textStatus, errorThrown) {
          __crearMensaje(true,"Error",jqXHR.statusText);
      }
    });


}


jQuery(function(){
  
  /**
   * Verificar los horarios en edición de restaurant
   * 
   */
   validaHorario();

    /*
    ** Llamada RPC para eliminar preguntas frecuentes
    ** @author Guillermo Hernandez
    */	 
    $('.elimina-pf').on('click', function(){
          id=parseInt($(this).attr('pid'));
          if(confirm('¿Deseas eliminar la pregunta seleccionada?')){
              $.ajax({
                  url: uDelPf,
                  type: 'DELETE',
                  data:'{"id":'+id+'}',
                  dataType: "json",
                  contentType: 'application/json',
                  success: function(data) {
                      __crearMensaje(false,'Información','Se ha marcado la pregunta como borrada');
                      location.reload();
                  },
                  error: function(jqXHR, textStatus, errorThrown) {
                      __crearMensaje(true,"Error",jqXHR.statusText);
                  }
                });
          }
    });

    
    /*
    ** Llamada RPC para restaurar una pregunta eliminada
    ** @author Guillermo Hernandez
    */	 
    $('.restore-pf').on('click', function(){
        id=parseInt($(this).attr('pid'));
        if(confirm('¿Deseas restaurar la pregunta seleccionada?')){
            $.ajax({
                url: uResPf,
                type: 'POST',
                dataType: "json",
                contentType: 'application/json',
                data:'{"id":'+id+'}',
                success: function(data) {
                    __crearMensaje(false,'Información','Se ha restaurado la pregunta');
                    location.reload();
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    __crearMensaje(true,"Error",jqXHR.statusText);
                }
              });
        }
    });

    
    
    /*
    ** Llamada RPC para eliminar una categoria de comida
    ** @author Guillermo Hernandez
    */	 
    $('.elimina-tc').on('click', function(){
        id=parseInt($(this).attr('tcid'));
        if(confirm('¿Deseas eliminar la categoría seleccionada?')){
            $.ajax({
                url: uDelTc,
                type: 'DELETE',
                data:'{"id":'+id+'}',
                dataType: "json",
                contentType: 'application/json',
                success: function(data) {
                    __crearMensaje(false,'Información','Se ha marcado la categoría como borrada');
                    location.reload();
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    __crearMensaje(true,"Error",jqXHR.statusText);
                }
              });
        }
    });

    
    
    /*
    ** Llamada RPC para restautar una categoria eliminada
    ** @author Guillermo Hernandez
    */
    $('.restore-tc').on('click', function(){
        id=parseInt($(this).attr('tcid'));
        if(confirm('¿Deseas restaurar la categoría seleccionada?')){
            $.ajax({
                url: uResTc,
                type: 'POST',
                dataType: "json",
                contentType: 'application/json',
                data:'{"id":'+id+'}',
                success: function(data) {
                    __crearMensaje(false,'Información','Se ha restaurado la categoría');
                    location.reload();
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    __crearMensaje(true,"Error",jqXHR.statusText);
                }
              });
        }
    });

    
    
    /*
    ** Llamada RPC para añadir una categoria al restaurant
    ** o la actualizacion del restaurant
    ** @author Guillermo Hernandez
    */
    $('#id_tipo_cocina').on('change', function(e){
        c = e.target.id;
        u = $(this).attr('do');
        $.ajax({
          url: u=='update'?uAtTcRu:uAtTcR,
          type: 'POST',
          data:'{"idTipoCocina":'+this.value+'}',
          dataType: "json",
          contentType: 'application/json',
          success: function(data) {
            cat = data.categoria;
            $('#categorias').append('<div id="c-'+cat.id+'" do="'+u+'" class="tc-item" item="'+cat.id+'">'+cat.nb_tipo_cocina+'<span>x</span></div>');
            __crearMensaje(false,'Información',data.mensaje);
            $('#id_tipo_cocina').val('');
          },
          error: function(jqXHR, textStatus, errorThrown) {
              __crearMensaje(true,"Error",jqXHR.responseJSON.mensaje||jqXHR.responseJSON.message);
          }
        });
    });



    /*
    ** Llamada RPC para actualizar campos
    ** y sus valores "no se pierdan" si falla
    ** validación del back
    ** @author Guillermo Hernandez
    */
    $('.frmRestaurantControl_r').on('change', function(e){
      d = e.target.name;
      v = $(this).is("input[type='checkbox']")?($(this).is(':checked')?1:0):$(this).val()
      //console.log(v);
      dataZ = '{"'+d+'":"'+v+'"}'
      $.ajax({
        url: uac,
        type: 'POST',
        data:dataZ,
        dataType: "json",
        contentType: 'application/json',
        success: function(data) {
        },
        error: function(jqXHR, textStatus, errorThrown) {
            __crearMensaje(true,"Error",jqXHR.statusText);
        }
      });

    });


    
    /*
    ** Funcion para centrar el mapa cerca de la direccion seleccionada
    ** @author Guillermo Hernandez
    */
    $('.geoControl').on('blur', function(e){
      buscar = true;
      if($('#tx_calle').val()==''){buscar = false;}
      if($('#tx_num_ext').val()==''){buscar = false;}
      // if($('#tx_codigo_postal').val()==''){buscar = false;}
      // if($('#id_alcaldia option:selected').text()==''){buscar = false;}
      // if($('#id_colonia option:selected').text()==''){buscar = false;}

      $('#fullAddress').val(
        $('#tx_calle').val() + ' ' +
        $('#tx_num_ext').val() +  ' ' +
        // $('#id_colonia option:selected' ).text() +  ' ' +
        // $('#id_alcaldia option:selected').text() + ' ' +
        $('#tx_codigo_postal').val()
      );
      if(buscar){
        codeAddress();
      }
    });


  
  
  /*
  ** Llamada RPC para eliminar una categoria del restaurant
  ** @author Guillermo Hernandez
  */	 
  $('#categorias').on('click', '.tc-item', function(e){
    c = e.target.id;
    u = $(this).attr('do');
    idTipoCocina = parseInt($(this).attr('item'));
    $.ajax({
      url: u=='update'?uDtTcRu:uDtTcR,
      type: 'DELETE',
      data:'{"idTipoCocina":'+idTipoCocina+'}',
      dataType: "json",
      contentType: 'application/json',
      success: function(data) {
        $('#c-'+idTipoCocina).remove();
        __crearMensaje(false,'Información',data.mensaje);
      },
      error: function(jqXHR, textStatus, errorThrown) {
          __crearMensaje(true,"Error",jqXHR.responseJSON.mensaje||jqXHR.responseJSON.message);
      }
    });
  });



  
  /*
  ** Funcion para mostrar u ocultar los campos de horario
  ** en el registro del restaurant
  ** @author Guillermo Hernandez
  */	 
  $('.chk-dia').on('click', function(e){
    c = e.target.id;
    d = $(this).attr('day');
    if($('#'+c).prop('checked')){
      $('#controles_'+d).show();
      $('#hm_abre_'+d).attr('disabled', false);
      $('#hm_cierra_'+d).attr('disabled', false);
    }else{
      $('#controles_'+d).hide();
      $('#hm_abre_'+d).attr('disabled', true);
      $('#hm_cierra_'+d).attr('disabled', true);
    }
    // Si no hay ninguno con palomita, mostrar aviso y desactivar submit
    validaHorario();
  });



  /*
  ** Funcion para seleccionar hora de cierre
  ** en el registro del restaurant
  ** @author Guillermo Hernandez
  */	 
  $('.sel-hora').on('change', function(e){
    c = e.target.id;
    d = $(this).attr('day');
    hoption = $('option:selected', this).attr('vn');
    $('#hm_cierra_'+d+' option').each(function() {
        $(this).remove();
    });
    horaInt = parseInt(hoption);
    horaFloat = parseFloat(hoption);
    esLaMedia = (horaFloat - horaInt) > 0;
    esEnPunto = (horaFloat - horaInt) == 0;
    if(esLaMedia){
      horaInt++;
    }
    for(h=horaInt;h<=23;h++){
      hora = h < 10 ? '0' + h : h;
      if(esEnPunto){
        $('#hm_cierra_'+d).append('<option value="'+hora+':30">'+hora+':30</option>');
        esEnPunto = false;
        continue;
      }
      $('#hm_cierra_'+d).append('<option value="'+hora+':00">'+hora+':00</option>');
      $('#hm_cierra_'+d).append('<option value="'+hora+':30">'+hora+':30</option>');
    }
  });


  /*
  ** Funcion para buscar colonias y alcaldias por código postal
  ** @author Guillermo Hernandez
  */	 
  $('#tx_codigo_postal').on('blur', function(e){
    if(/^[0-9]{5}$/.test($(this).val())){
      $.ajax({
        url: ubase+'/service/u/alcaldiaColoniaByCP/'+this.value,
        dataType: 'json',
        contentType: 'application/json',
        type: 'POST',
        success: function(data) {
            // Quitar Alcaldía
            $('#id_alcaldia option').each(function() {
              if ( $(this).val() !== '' ) {
                $(this).remove();
              }
            });
            // Quitar Colonias
            $('#id_colonia option').each(function() {
              if ( $(this).val() !== '' ) {
                $(this).remove();
              }
            });
            arCodes=data.cp;
            if(arCodes.length === 0){
              __crearMensaje(true,"Error","No es un código postal de CDMX");
              $('#tx_codigo_postal').val('');
            }else{

              idAlcaldia = data.alcaldia.id;
              nbAlcaldia = data.alcaldia.nb_alcaldia;
              $('#id_alcaldia').append('<option value="'+idAlcaldia+'" v="'+nbAlcaldia+'">'+nbAlcaldia+'</option>');

              for(c=0;c<arCodes.length;c++){
                idColonia = arCodes[c].id;
                nbColonia = arCodes[c].nb_asentamiento;
                $('#id_colonia').append('<option value="'+idColonia+'" v="'+nbColonia+'">'+nbColonia+'</option>');
              }
              // enviar alcaldia y cp
              setAlcaldiaColonia(idAlcaldia, arCodes[0].id)
            }
            
          },
          error: function(jqXHR, textStatus, errorThrown) {
            err = jqXHR.status ==419?'Tu sesión ha caducado, por favor inicia sesión de nuevo':"No pudimos <br>Código de error: " + jqXHR.status + " " + textStatus;
            __crearMensaje(true,"Error",err);
          }
        }
      );
    }else{
      __crearMensaje(true,"Error","No es un código postal válido");
      $(this).val('');
    }
  });

    /**
     * Visor de archivos
     */
     $('#fv-modal').on('show.bs.modal', function (event) {
      var v = $(event.relatedTarget);
      uuid = v.data('file-viewer-id');
      t = v.data('type');
      stil = t.indexOf('pdf') > -1 ? ' style="height: 100vh; min-height: 100vh; mon-width:100%" ' : '';
      newURL = ubase + '/service/storage/public/' + uuid;
      $('object').remove();
      $('<object id="fv-content" data="' + newURL + '" type="' + t + '"' + stil + '></object>').appendTo('#fv-modal .modal-body');
      //$('#fv-content').attr('data',newURL);
  });


  /**
   * Mostrar la caja de comentarios para secciones no válidas
   * 
   */
   $('.btn-incorrect').on('click', function(e){
      sektion = $(this).attr('secc');
      _do = $(this).attr('do');
      urlSufix = _do=='update'?'-update':'';
      leId = 'btn-incorrect-'+sektion;
      elOtro = 'btn-correct-'+sektion;
      dataZ={sec:sektion, v:0};
      $.ajax({
        url: ubase+'/backoffice/deny'+urlSufix,
        dataType: 'json',
        contentType: 'application/json',
        type: 'PUT',
        data:JSON.stringify(dataZ),
        success: function(data) {
          // quitar botones
          //$('#botones-'+sektion).remove();
          $('#'+leId).addClass('active');
          $('#'+leId).removeClass('other');
          $('#'+elOtro).addClass('other');
          $('#'+elOtro).removeClass('active');
          // mostrar para detalles
          $('#comments_'+sektion).attr('disabled', false);
          $('#acepta-secc-'+sektion).hide();
          $('#rechaza-secc-'+sektion).show();
          __crearMensaje(false,"Información",data.mensaje);
          $('#btn-rechazar').attr('disabled', !data.nokButtons);
          //$('#btn-corregir').attr('disabled', !data.nokButtons);
          $('#btn-ok').attr('disabled', !data.okButton);
            
        },
        error: function(jqXHR, textStatus, errorThrown) {
          err = jqXHR.status == 419?
                'Tu sesión ha caducado, por favor inicia sesión de nuevo':
                "No pudimos completar la solicitud <br>Código de error: " + jqXHR.status + " " + textStatus;
          __crearMensaje(true,"Error",err);
        }
      });


  });


  /**
   * Mostrar que la seccion está ok
   * 
   */
  $('.btn-correct').on('click', function(e){
    sektion = $(this).attr('secc');
    _do = $(this).attr('do');
    urlSufix = _do=='update'?'-update':'';
    leId = 'btn-correct-'+sektion;
    elOtro = 'btn-incorrect-'+sektion;
    dataZ={sec:sektion, v:1};
    $.ajax({
      url: ubase+'/backoffice/approve'+urlSufix,
      dataType: 'json',
      contentType: 'application/json',
      type: 'PUT',
      data:JSON.stringify(dataZ),
      success: function(data) {
          // quitar botones
          // $('#botones-'+sektion).remove();
          // $('#rechaza-secc-'+sektion).remove();
          $('#'+leId).addClass('active');
          $('#'+leId).removeClass('other');
          $('#'+elOtro).addClass('other');
          $('#'+elOtro).removeClass('active');
          // ocultar detalles
          $('#comments_'+sektion).attr('disabled', true);
          $('#rechaza-secc-'+sektion).hide();
          $('#acepta-secc-'+sektion).show();
          __crearMensaje(false,"Información",data.mensaje);
          __crearMensaje(false,"Información",data.mensaje);
          $('#btn-rechazar').attr('disabled', !data.nokButtons);
          $('#btn-corregir').attr('disabled', !data.nokButtons);
          $('#btn-ok').attr('disabled', !data.okButton);
        },
      error: function(jqXHR, textStatus, errorThrown) {
        err = jqXHR.status == 419?
        'Tu sesión ha caducado, por favor inicia sesión de nuevo':
        "No pudimos completar la solicitud <br>Código de error: " + jqXHR.status + " " + textStatus;
        __crearMensaje(true,"Error",err);
      }
    });
  });



  /**
   * Restaurante OK
   * 
   */
  $('#btn-ok').on('click', function(e){
     $(this).attr('disabled', true);
     _do = $(this).attr('do');
     urlSufix = _do=='update'?'-update':'';
    $.ajax({
      url: ubase+'/backoffice/restaurant-aprobado',
      dataType: 'json',
      contentType: 'application/json',
      type: 'POST',
      success: function(data) {
        __crearMensaje(false,"Información",data.mensaje);
        location.href=ubase+'/backoffice/restaurant-aprobado'+urlSufix;
      },
      error: function(jqXHR, textStatus, errorThrown) {
        err = jqXHR.status == 419?
        'Tu sesión ha caducado, por favor inicia sesión de nuevo':
        "No pudimos completar la solicitud <br>Código de error: " + jqXHR.status + " " + textStatus;
        __crearMensaje(true,"Error",err);
      }
    });
  });

  /**
   * Restaurante rechazado
   * 
   */
  $('#btn-rechazar, #btn-corregir').on('click', function(e){
    a = e.target.id == 'btn-rechazar'?'/backoffice/restaurant-rechazado': '/backoffice/restaurant-corregir';
    _do = $(this).attr('do');
    urlSufix = _do=='update'?'-update':'';
    formcito = document.getElementById('frmVerificaRestaurant');
    dataZ = new FormData(formcito);
    dataZ.append('c',e.target.id);
    if(formcito.checkValidity()){
      $(this).attr('disabled', true);
      $.ajax({
        url: ubase+a,
        contentType: false,
        type: 'POST',
        data: dataZ,
        processData: false,
        success: function(data) {
          console.log(data);
          __crearMensaje(false,"Información",data.mensaje);
          location.href=ubase+a+urlSufix;
        },
        error: function(jqXHR, textStatus, errorThrown) {
          err = jqXHR.status == 419?
          'Tu sesión ha caducado, por favor inicia sesión de nuevo':
          "No pudimos completar la solicitud <br>Código de error: " + jqXHR.status + " " + textStatus;
          __crearMensaje(true,"Error",err);
          $('#'+a).attr('disabled', false);
        }
      });
    }else{
      $('#frmVerificaRestaurant').addClass('was-validated');
      __crearMensaje(true,"Error",'Por favor revisa los campos marcados con rojo');
    }
  });



});