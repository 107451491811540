$('.custom-file').on('change', 'input:file', function () {
    var input = document.getElementById(this.id);
    var fileName = input.files[0].name;
    var input_id = this.id;
    var label = $("label[for=" + input_id + "]");
    $('#borrar-' + input_id).removeClass('d-none');
    label.html(fileName);
});
$('.btn-borrar_file').on("click", function () {
    var id = this.id;
    var name = id.split("-");
    $('#borrar-' + name[1]).addClass('d-none');
    document.getElementById(name[1]).value = "";
    $("label[for=" + name[1] + "]").html('Adjuntar archivo');
    return false;
});
